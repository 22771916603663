import React from 'react';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Compact } from '@uiw/react-color';
import iconColorWheel from '../icons/icon_colorWheel.png';
import Accordion from './accordion';
import { renderImages } from '../utilities/helper';

interface ImageListInterface {
    setBackgroundImage: Dispatch<SetStateAction<string>>;
    setBackgroundColor: Dispatch<SetStateAction<string>>;
    setGradientTop: Dispatch<SetStateAction<string>>;
    setGradientBottom: Dispatch<SetStateAction<string>>;
    gradientTop: string;
    gradientBottom: string;
    backgroundColor: string;
}

const ImageList = (props: ImageListInterface) => {
    const images = require.context('../backgrounds', true);
    const imageList = images.keys().map(image => images(image));
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showTopGradientPicker, setShowTopGradientPicker] = useState(false);
    const [showBottomGradientPicker, setShowBottomGradientPicker] = useState(false);

    useEffect(() => {
        const hexColor = JSON.parse(localStorage.getItem('hexColor')!);
        if (hexColor) {
            props.setBackgroundColor(hexColor);
        }
    }, []);

    function handleBackgroundImage(selectedBackgroundImage: any) {
        props.setBackgroundImage(selectedBackgroundImage);
    }

    function handleSolidBackground(hexColor: string): void {
        props.setBackgroundImage('');
        props.setBackgroundColor(hexColor);
        localStorage.setItem("backgroundColor", JSON.stringify(hexColor));
        document.getElementById('content')!.style.backgroundColor = `${hexColor}`;
    }

    function hexToRGB(hex: string, alpha?: number) {
        var m = hex.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
        return `rgba(${parseInt(m![1], 16)},${parseInt(m![2], 16)},${parseInt(m![3], 16)},${alpha})`;
    }

    return (
        <div className='imagelist'>
            <Accordion
                title={'Bakgrunder'}
                childClassName={'imagelist'}
            >
                <div className='row gradient'>
                    <>
                    <span>Gradient</span>
                        <button
                            className='btn btn-primary'
                            style={{ backgroundColor: props.gradientTop }}
                            onClick={() => setShowTopGradientPicker(!showTopGradientPicker)}>
                            <span style={{ mixBlendMode: 'difference' }}>Övre</span>
                        </button>
                        {showTopGradientPicker ?
                            <div className='color_picker'>
                                <Compact
                                    style={{ marginLeft: 20 }}
                                    onChange={(color) => {
                                        props.setGradientTop(hexToRGB(color.hex, 255));
                                        setShowTopGradientPicker(!showTopGradientPicker);
                                    }}
                                    rectRender={(element) => {
                                        if (element.key == 15) {
                                            return <button style={{ border: 'none' }} key={element.key} onClick={() => {
                                                props.setGradientTop(hexToRGB('#000000', 0));
                                                setShowTopGradientPicker(!showTopGradientPicker);
                                            }}>✕</button>
                                        }
                                    }}
                                />
                            </div>
                            : null
                        }
                        <button
                            className='btn btn-primary'
                            style={{ backgroundColor: props.gradientBottom}}
                            onClick={() => setShowBottomGradientPicker(!showBottomGradientPicker)}>
                            <span style={{ mixBlendMode: 'difference' }}>Nedre</span>
                        </button>
                        {showBottomGradientPicker ?
                            <div className='color_picker'>
                                <Compact
                                    style={{ marginLeft: 20 }}
                                    onChange={(color) => {
                                        props.setGradientBottom(hexToRGB(color.hex, 255));
                                        setShowBottomGradientPicker(!showBottomGradientPicker);
                                    }}
                                    rectRender={(element) => {
                                        if (element.key == 15) {
                                            return <button style={{ border: 'none' }} key={element.key} onClick={() => {
                                                props.setGradientBottom(hexToRGB('#000000', 0));
                                                setShowBottomGradientPicker(!showBottomGradientPicker);
                                            }}>✕</button>
                                        }
                                    }}
                                />
                            </div>
                            : null
                        }
                    </>
                </div>
                <div className='row background_selector'>
                    <img className='picker' alt='Color picker' src={iconColorWheel} onClick={() => setShowColorPicker(!showColorPicker)} />
                    <div className='color_picker'>
                        {showColorPicker ?
                            <Compact
                                style={{ marginLeft: 20 }}
                                onChange={(color) => {
                                    handleSolidBackground(color.hex);
                                    setShowColorPicker(!showColorPicker);
                                }}
                            />
                            : null
                        }
                    </div>
                    {renderImages(imageList, handleBackgroundImage)}
                </div>
            </Accordion>
        </div>
    );
}

export default ImageList;