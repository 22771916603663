import * as React from 'react'
import instagram from '../icons/icon_instagram.svg';
import logo from '../icons/logo_quidster.svg';

const Footer = () => {
    let date = new Date();
    let displayYear = date.getFullYear();

    return (
        <div id="footer" className='footer'>
            <div className='copy'>
            <img src={logo} alt='logo' className='icon' /> {displayYear}
            </div>
            <div className='info'>
                Följ oss och se exempel på <a href='https://www.instagram.com/quidsterapp/' target='_blank'><img src={instagram} alt='instagram' className='icon' /></a>
            </div>
        </div>
    )
}

export default Footer