import React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { renderImages } from '../utilities/helper';

interface RightSidebarInterface {
    rightSidebarOpen: boolean;
    setRightSideBarOpen: Dispatch<SetStateAction<boolean>>;
}

const RightSidebar = (props: RightSidebarInterface) => {
    // const images = require.context('./examples', true);
    const images = require.context('../../public/examples', true);
    const imageList = images.keys().map(image => images(image));
    const sidebarClass = props.rightSidebarOpen ? "right-sidebar open" : "right-sidebar";
    return (
        <div className={`sidebar ${sidebarClass}`}>
            <h4>Inspiration & exempel</h4>
            <p>Se fler exempel på vår <a href='https://instagram.com/quidsterapp' target='_blank'>instagram</a></p>
            <div className='right-content'>
                <div className='image-list'>
                    {renderImages(imageList, () => { })}
                </div>
            </div>
            <button
                className="right-sidebar-toggle"
                onClick={() => props.setRightSideBarOpen(!props.rightSidebarOpen)}
            >
                Inspiration!
            </button>
        </div>
    );
}

export default RightSidebar;