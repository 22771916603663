// Import React
import * as React from 'react'
import strings from '../utilities/strings';
import Accordion from './accordion';
import { useState, useEffect } from 'react';
import FontPicker from '@techstack/font-picker-react';
import { colorPicker } from '../utilities/helper';

// Interface for Form Component
interface FormInterface {
  isBannerGenerated: boolean;
  textPrimary: string;
  textSecondary: string;
  textTertiary: string;
  textQuaternary: string;
  textQuinary: string;
  textSenary: string;
  textSeptenary: string;
  textOctonary: string;
  logo: string;
  moveableSecondaryImage: string;
  moveablePrimaryImage: string;
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  septenaryColor: string;
  octonaryColor: string;
  tertiaryBgColor: string;
  secondaryBgColor: string;
  activeFont: string;
  showLove: boolean;
  textPrimarySize: string;
  textSenarySize: string;
  logo64: string;
  primaryBase64: string;
  secondaryBase64: string;
  instagramSize: string;
  setTextPrimarySize: React.Dispatch<React.SetStateAction<string>>;
  setTextSenarySize: React.Dispatch<React.SetStateAction<string>>;
  setResultDimensions: React.Dispatch<React.SetStateAction<Object>>;
  setLogo: React.Dispatch<React.SetStateAction<string>>;
  setLogo64: React.Dispatch<React.SetStateAction<string>>;
  setMoveableSecondaryImage: React.Dispatch<React.SetStateAction<string>>;
  setShowLove: React.Dispatch<React.SetStateAction<boolean>>;
  setMoveablePrimaryImage: React.Dispatch<React.SetStateAction<string>>;
  setPrimaryColor: React.Dispatch<React.SetStateAction<string>>;
  setSecondaryColor: React.Dispatch<React.SetStateAction<string>>;
  setTertiaryColor: React.Dispatch<React.SetStateAction<string>>;
  setSeptenaryColor: React.Dispatch<React.SetStateAction<string>>;
  setOctonaryColor: React.Dispatch<React.SetStateAction<string>>;
  setSecondaryBgColor: React.Dispatch<React.SetStateAction<string>>;
  setTertiaryBgColor: React.Dispatch<React.SetStateAction<string>>;
  setActiveFont: React.Dispatch<React.SetStateAction<string>>;
  setInstagramSize: React.Dispatch<React.SetStateAction<string>>;
  handleImageLogoChange: (event: React.ChangeEvent) => void;
  handleMoveablePrimaryImageChange: (event: React.ChangeEvent) => void;
  handleMoveableSecondaryImageChange: (event: React.ChangeEvent) => void;
  handleInputChange: (event: React.ChangeEvent) => void;
  handleBannerGeneration: () => void;
  handleBannerReset: () => void;
  setPrimaryBase64: React.Dispatch<React.SetStateAction<string>>;
  setSecondaryBase64: React.Dispatch<React.SetStateAction<string>>;
}

// Form component
const Form = (props: FormInterface) => {
  const [showPrimaryColorPicker, setShowPrimaryColorPicker] = useState(false);
  const [showSecondaryColorPicker, setShowSecondaryColorPicker] = useState(false);
  const [showTertiaryColorPicker, setShowTertiaryColorPicker] = useState(false);
  const [showSecondaryBgColorPicker, setShowSecondaryBgColorPicker] = useState(false);
  const [showTertiaryBgColorPicker, setShowTertiaryBgColorPicker] = useState(false);
  const [showSeptenaryColorPicker, setShowSeptenaryColorPicker] = useState(false);
  const [showOctonaryColorPicker, setShowOctonaryColorPicker] = useState(false);
  const textSize = [90, 72, 66, 54, 48, 38, 26];

  useEffect(() => {
    props.setTextPrimarySize(props.textPrimarySize);
    props.setTextSenarySize(props.textSenarySize);
  }, []);

  function handlePrimaryTextSizeChange(fontSize): void {
    props.setTextPrimarySize(fontSize);
  }

  function handleSenaryTextSizeChange(fontSize): void {
    props.setTextSenarySize(fontSize);
  }

  return (
    <div id='form' className='form'>
      <Accordion
        title={'Formulär'}
        childClassName='form'
      >
        <div className='form_inputs'>
          <div className='row'>
            <div className='column'>
              Välj typsnitt från Google Fonts
            </div>
            <div className='column'>
              <FontPicker
                apiKey="AIzaSyDCIpNVW4CIZ2fQz-TDtQaImLf2iIm9wSc"
                activeFontFamily={props.activeFont}
                onChange={(nextFont) =>
                  props.setActiveFont(nextFont.family)
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='column'>
              <div id="font-picker"></div>
              <div className='form_input_size'>
                <input
                  name='text-primary'
                  placeholder={strings.placeholder.matchday}
                  type='text'
                  value={props.textPrimary}
                  onChange={props.handleInputChange}
                />
                <button className='btn btn-primary' type='button'
                  style={{ backgroundColor: props.primaryColor, color: props.primaryColor === '#ffffff' ? '#000' : '#fff', border: props.primaryColor === '#ffffff' ? '1px solid #000' : 'none' }}
                  onClick={() => setShowPrimaryColorPicker(!showPrimaryColorPicker)}>{strings.buttons.textColor}
                </button>
                {showPrimaryColorPicker ? colorPicker(props.primaryColor, props.setPrimaryColor, setShowPrimaryColorPicker, showPrimaryColorPicker) : null}
                <select
                  id='text-primary-size'
                  onChange={e => handlePrimaryTextSizeChange(e.target.value)}
                >
                  <option>{props.textPrimarySize}</option>
                  {
                    textSize.map((size, index) => (
                      <option key={index} value={size}>{size}</option>
                    ))
                  }
                </select>
              </div>
              <div className='form_input_color'>
                <input
                  name='text-tertiary'
                  placeholder={strings.placeholder.hometeam}
                  type='text'
                  value={props.textTertiary}
                  onChange={props.handleInputChange}
                />
                <button className='btn btn-primary' type='button' style={{ backgroundColor: props.tertiaryColor, color: props.tertiaryColor === '#ffffff' ? '#000' : '#fff', border: props.tertiaryColor === '#ffffff' ? '1px solid #000' : 'none' }} onClick={() => setShowTertiaryColorPicker(!showTertiaryColorPicker)}>{strings.buttons.textColor}</button>
                {showTertiaryColorPicker ? colorPicker(props.tertiaryColor, props.setTertiaryColor, setShowTertiaryColorPicker, showTertiaryColorPicker) : null}
                <button className='btn btn-primary' type='button'
                  style={{ backgroundColor: props.tertiaryBgColor, color: props.tertiaryBgColor === '#ffffff' ? '#000' : '#fff', border: props.tertiaryBgColor === '#ffffff' ? '1px solid #000' : 'none' }}
                  onClick={() => setShowTertiaryBgColorPicker(!showTertiaryBgColorPicker)}>{strings.buttons.backgroundColor}
                </button>
                {showTertiaryBgColorPicker ? colorPicker(props.tertiaryBgColor, props.setTertiaryBgColor, setShowTertiaryBgColorPicker, showTertiaryBgColorPicker) : null}
              </div>
              <input
                name='text-quinary'
                placeholder={strings.placeholder.time}
                type='text'
                value={props.textQuinary}
                onChange={props.handleInputChange}
              />
            </div>
            <div className='column'>
              <div className='form_input_size'>
                <input
                  name='text-senary'
                  placeholder={strings.placeholder.Senary}
                  type='text'
                  value={props.textSenary}
                  onChange={props.handleInputChange}
                />
                <select id='text-senary-size' onChange={e => handleSenaryTextSizeChange(e.target.value)}>
                  <option>{props.textSenarySize}</option>
                  {
                    textSize.map((size, index) => (
                      <option key={index} value={size}>{size}</option>
                    ))
                  }
                </select>
              </div>
              <div className='form_input_color'>
                <input
                  name='text-secondary'
                  placeholder={strings.placeholder.awayteam}
                  type='text'
                  value={props.textSecondary}
                  onChange={props.handleInputChange}
                />
                <button className='btn btn-primary' type='button' style={{ backgroundColor: props.secondaryColor, color: props.secondaryColor === '#ffffff' ? '#000' : '#fff', border: props.secondaryColor === '#ffffff' ? '1px solid #000' : 'none' }} onClick={() => setShowSecondaryColorPicker(!showSecondaryColorPicker)}>{strings.buttons.textColor}</button>
                {showSecondaryColorPicker ? colorPicker(props.secondaryColor, props.setSecondaryColor, setShowSecondaryColorPicker, showSecondaryColorPicker) : null}
                <button className='btn btn-primary' type='button'
                  style={{ backgroundColor: props.secondaryBgColor, color: props.secondaryBgColor === '#ffffff' ? '#000' : '#fff', border: props.secondaryBgColor === '#ffffff' ? '1px solid #000' : 'none' }}
                  onClick={() => setShowSecondaryBgColorPicker(!showSecondaryBgColorPicker)}>{strings.buttons.backgroundColor}
                </button>
                {showSecondaryBgColorPicker ? colorPicker(props.secondaryBgColor, props.setSecondaryBgColor, setShowSecondaryBgColorPicker, showSecondaryBgColorPicker) : null}
              </div>
              <input
                name='text-quaternary'
                placeholder={strings.placeholder.venue}
                type='text'
                value={props.textQuaternary}
                onChange={props.handleInputChange}
              />
            </div>
          </div>
          <div className='row'>
            <div className='column'>
              <div className='form_input_color'>
                <textarea
                  name='text-septenary'
                  placeholder={strings.placeholder.Septenary}
                  rows={4}
                  cols={40}
                  value={props.textSeptenary}
                  onChange={props.handleInputChange}
                  style={{ width: props.activeFont }}
                />
                {showSeptenaryColorPicker ? colorPicker(props.septenaryColor, props.setSeptenaryColor, setShowSeptenaryColorPicker, showSeptenaryColorPicker) : null}
                <button className='btn btn-primary btn-textarea' type='button'
                  style={{ backgroundColor: props.septenaryColor, color: props.septenaryColor === '#ffffff' ? '#000' : '#fff', border: props.septenaryColor === '#ffffff' ? '1px solid #000' : 'none' }}
                  onClick={() => setShowSeptenaryColorPicker(!showSeptenaryColorPicker)}>
                  {strings.buttons.textColor}
                </button>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='column'>
              <div className='form_input_color'>
                <textarea
                  name='text-octonary'
                  placeholder={strings.placeholder.Octonary}
                  rows={4}
                  cols={40}
                  value={props.textOctonary}
                  onChange={props.handleInputChange}
                />
                {showOctonaryColorPicker ? colorPicker(props.octonaryColor, props.setOctonaryColor, setShowOctonaryColorPicker, showOctonaryColorPicker) : null}
                <button className='btn btn-primary btn-textarea' type='button'
                  style={{ backgroundColor: props.octonaryColor, color: props.octonaryColor === '#ffffff' ? '#000' : '#fff', border: props.octonaryColor === '#ffffff' ? '1px solid #000' : 'none' }}
                  onClick={() => setShowOctonaryColorPicker(!showOctonaryColorPicker)}>
                  {strings.buttons.textColor}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Accordion>
      <Accordion
        title={'Kontroller'}
        childClassName='kontroller'
      >
        <div className='form_btns kontroller-first'>
          <button className={`btn ${props.instagramSize == 'portrait' ? 'btn-active':'btn-primary'}`} type='button' onClick={() => { props.setInstagramSize('portrait'), props.setResultDimensions({height: strings.size.portrait.height, width: strings.size.portrait.width}) }}>{strings.buttons.instaPortrait}</button>
          <button className={`btn ${props.instagramSize == 'square' ?'btn-active':'btn-primary'}`} type='button' onClick={() => { props.setInstagramSize('square'), props.setResultDimensions({height: strings.size.square.height, width: strings.size.square.width}) }}>{strings.buttons.instaSquare}</button>
          <button className={`btn ${props.instagramSize == 'story' ?'btn-active':'btn-primary'}`} type='button' onClick={() => { props.setInstagramSize('story'), props.setResultDimensions({height: strings.size.story.height, width: strings.size.story.width}) }}>{strings.buttons.instaStory}</button>
        </div>
        <div className='form_btns kontroller-second'>
          {!props.logo ?
            <label
              className='btn btn-primary'
              htmlFor='logoFileInput'
            >
              {strings.buttons.uploadBackgroundImage}
              <input id='logoFileInput' name='logoFileInput' type='file' accept='.jpg, .jpeg, .png' onChange={props.handleImageLogoChange} hidden />
            </label>
            :
            <label
              className='btn btn-primary'
              htmlFor='removeLogoFileInput'
            >
              {strings.buttons.removeBackgroundImage}
              <button
                id='removeLogoFileInput'
                name='removeLogoFileInput'
                onClick={() => {
                  props.setLogo(null)
                  props.setLogo64(null);
                }}
                hidden
              />
            </label>
          }
          {!props.moveablePrimaryImage ?
            <label
              className='btn btn-primary'
              htmlFor='filePrimaryImageInput'
            >
              {strings.buttons.uploadPrimaryMoveableImage}
              <input id='filePrimaryImageInput' name='filePrimaryImageInput' type='file' accept='.jpg, .jpeg, .png' onChange={props.handleMoveablePrimaryImageChange} hidden />
            </label>
            :
            <label
              className='btn btn-primary'
              htmlFor='removefilePrimaryImageInput'
            >
              {strings.buttons.removePrimaryMoveableImage}
              <button
                id='removefilePrimaryImageInput'
                name='removefilePrimaryImageInput'
                onClick={() => {
                  props.setMoveablePrimaryImage(null);
                  props.setPrimaryBase64(null);
                }}
                hidden />
            </label>
          }
          {!props.moveableSecondaryImage ?
            <label
              className='btn btn-primary'
              htmlFor='fileSecondaryImageInput'
            >
              {strings.buttons.uploadSecondaryMoveableImage}
              <input id='fileSecondaryImageInput' name='fileSecondaryImageInput' type='file' accept='.jpg, .jpeg, .png' onChange={props.handleMoveableSecondaryImageChange} hidden />
            </label>
            :
            <label
              className='btn btn-primary'
              htmlFor='removefileSecondaryImageInput'
            >
              {strings.buttons.removeSecondaryMoveableImage}
              <button
                id='removefileSecondaryImageInput'
                name='removefileSecondaryImageInput'
                onClick={() => {
                  props.setMoveableSecondaryImage(null)
                  props.setSecondaryBase64(null);
                }}
                hidden
              />
            </label>
          }
          {!props.isBannerGenerated ?
            <button
              id='generate-banner'
              className='btn btn-generate'
              type='button'
              onClick={props.handleBannerGeneration}
            >
              {strings.buttons.generate}
            </button>
            :
            <button
              className='btn btn-danger'
              type='button'
              onClick={props.handleBannerReset}
            >
              {strings.buttons.reset}
            </button>}
          <label
            htmlFor='showLove'
            onChange={() => props.setShowLove(!props.showLove)}
          >
            {strings.buttons.copy}
            <input
              id='showLove'
              name='showLove'
              type='checkbox'
              checked={props.showLove}
              onChange={() => props.setShowLove(!props.showLove)}
            />
          </label>
        </div>
      </Accordion>
    </div>
  )
}

export default Form
