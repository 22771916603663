import * as React from 'react'
import { useState } from 'react';
import chevronUp from '../icons/icon_chev-up.svg';
import chevronDown from '../icons/icon_chev-down.svg';

interface AccordionInterface {
  title: string;
  content?: string;
  children: React.ReactNode;
  childClassName?: string;
}

const Accordion = (props: AccordionInterface) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className="accordion-item">
      <button
        className='btn-primary accordion-button'
        type='button'
        style={{backgroundColor:isActive? '#037ebe' : '#025D8C' }}
        onClick={() => setIsActive((prevIsActive) => !prevIsActive)}
      >
        {props.title}{isActive ? <img src={chevronUp} alt='Chevron up' className='icon' /> : <img src={chevronDown} alt='Chevron down' className='icon' />}
      </button>
      <div className={`accordion-content ${props.childClassName} ${isActive ? 'visible' : 'hidden'}`}>{props.content}{props.children}</div>
    </div>
  )
}

export default Accordion