import React from "react";
import { Compact } from '@uiw/react-color';

export function renderImages(
    images: any,
    onClickFunction: (image: any) => void
    ) {
    return images.map((image: any, index: number) => (
        <img
            key={index}
            src={image}
            alt={`image-${index}`}
            onClick={() => {
                onClickFunction(image)
            }}
        />
    ));
};

export function createBlobFromBase64(
    blob:Blob, 
    setState: (state: string) => void
    ) {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = ()=> {
      setState(reader.result as string);                
    }
  }

export function colorPicker(colorProps, setColor, setShow, setShowState) {
    return (
      <div className='color_picker'>
        <Compact
          style={{ marginLeft: 20 }}
          color={colorProps}
          onChange={(color) => {
            setColor(color.hex);
            setShow(!setShowState);
          }}
          rectRender={(element) => {
            if (element.key == 15) {
              return <button style={{ border: 'none' }} key={element.key} onClick={() => { setColor(null); setShow(!setShowState); }}>✕</button>
            }
          }}
        /></div>
    );
  }