import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

export const Contact = () => {
    const form = useRef<HTMLFormElement | null>(null);
    const [emailSent, setEmailSent] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const sendEmail = (e: React.FormEvent) => {
        setEmailLoading(true);
        e.preventDefault();

        if (form.current) {
            emailjs
                .sendForm('loopia_irknzj8', 'template_g7hftbb', form.current, 'WypgR_yayvNeSXgJn')
                .then(
                    (result) => {
                        setEmailSent(true);
                        console.log(result.text);
                    },
                    (error) => {
                        setEmailSent(false);
                        setEmailError(true);
                        setEmailErrorMessage(error.text);
                    }
                );
        }
    };

    return (
        emailSent ?
            <>
                <div className='message-sent'>Tack för ditt meddelande!</div>
                <button className='btn-primary' onClick={() => { setEmailSent(false); setEmailLoading(false) }}>Skicka nytt</button>
            </>
            :
            <>
                {emailError && <div>Något gick fel: {emailErrorMessage}</div>}
                {emailLoading && <div className="lds-ring"><div></div><div></div><div></div><div></div></div>}
                {!emailLoading && <form className='sidebar-form' ref={form} onSubmit={sendEmail}>
                    <input type="text" name="user_name" placeholder='Namn' />
                    <input type="email" name="user_email" placeholder='Email' />
                    <textarea placeholder='Meddelande' name="message" />
                    <input className='btn-primary' type="submit" value="Skicka" />
                </form>
                }
            </>

    );
};
