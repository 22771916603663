import React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { Contact } from './contact';

interface LeftSidebarInterface {
    leftSidebarOpen: boolean;
    setLeftSideBarOpen: Dispatch<SetStateAction<boolean>>;
}

const LeftSidebar = (props: LeftSidebarInterface) => {
    const sidebarClass = props.leftSidebarOpen ? "left-sidebar open" : "left-sidebar";
    return (
        <div className={`sidebar ${sidebarClass}`}>
            <h4>Hjälp, förslag eller buggar?</h4>
            <div className='form_inputs'><Contact /></div>
            <button
                className="left-sidebar-toggle"
                onClick={() => props.setLeftSideBarOpen(!props.leftSidebarOpen)}
            >
                Hjälp!
            </button>
            <h4>Vanliga frågor</h4>
            <p>Vart börjar jag? <br/><span style={{fontSize: '12px'}}>Under formulär fyller du i din text. I kontroller laddar du upp bilder och genererar din poster (Grön knapp). Under Bakgrunder gör du dina bakgrundsval</span></p>
            <p>Vart sparas all data? <br/><span style={{fontSize: '12px'}}>I din webläsares lokala minne (localstorage)</span></p>
            <p>Hur tar jag bort all data? <br/><span style={{fontSize: '12px'}}><button className='btn-danger' style={{marginTop: 5}} onClick={() => {window.localStorage.clear(), location.reload()}}>Töm cache</button></span></p>
            <p>Lagrar Quidster min data? <br/><span style={{fontSize: '12px'}}>Vi lagrar bara det som du fyller in i formulären</span></p>
        </div>
    );
}

export default LeftSidebar;