import * as React from 'react'
import { Rnd } from "react-rnd";
import quidsterLogo from '../icons/logo_quidster.svg';

interface ContentInterface {
  backgroundImage: string;
  activeFont: string;
  logo: string;
  moveableSecondaryImage: string;
  showLove: boolean;
  moveablePrimaryImage: string;
  contentContainerRef: React.RefObject<any>;
  textPrimarySize: string;
  textPrimary: string;
  textSecondary: string;
  textTertiary: string;
  textQuaternary: string;
  textQuinary: string;
  textSenary: string;
  textSenarySize: string;
  textSeptenary: string;
  textOctonary: string;
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  septenaryColor: string;
  octonaryColor: string;
  secondaryBgColor: string;
  tertiaryBgColor: string;
  gradientTop: string;
  gradientBottom: string;
  instagramSize: string;
}

const Content = (props: ContentInterface) => {
  if (props.instagramSize === 'portrait') {
    props.contentContainerRef.current.style.height = '675px'
    props.contentContainerRef.current.style.width = '540px'
  } else if (props.instagramSize === 'square') {
    props.contentContainerRef.current.style.height = '540px'
    props.contentContainerRef.current.style.width = '540px'
  } else if (props.instagramSize === 'story') {
    props.contentContainerRef.current.style.height = '960px'
    props.contentContainerRef.current.style.width = '540px'
  }
  
  return (
    <div id='content' className='content' ref={props.contentContainerRef}
      style={{
        height: '675px',
        width: '540px',
        font: props.activeFont,
        fontFamily: props.activeFont,
        backgroundImage: "url(" + `${props.backgroundImage}` + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className='gradient' 
        style={{ 
          position: 'absolute',
          bottom: 0,
          height: '100%',
          width: '100%',
          background: `linear-gradient(${props.gradientTop}, ${props.gradientBottom})`,
          zIndex: 8,
          }}>
      </div>
      {props.moveablePrimaryImage &&
        <Rnd
          id='move-primary-image'
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: "url(" + `${props.moveablePrimaryImage}` + ")",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            zIndex: 9
          }}
          default={{
            x: 0,
            y: 0,
            width: 400,
            height: 400
          }}
        />
      }
      {props.moveableSecondaryImage &&
        <Rnd
          id='move-secondary-image'
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: "url(" + `${props.moveableSecondaryImage}` + ")",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            zIndex: 9
          }}
          default={{
            x: 300,
            y: 0,
            width: 400,
            height: 400
          }}
        />
      }
      {props.logo ? <img className='content-background-image' src={props.logo} alt='Logo' /> : ''}
      <h1 className='apply-font content-text header-text text-primary' style={{ fontSize: parseInt(props.textPrimarySize), color: props.primaryColor, whiteSpace: 'pre-line' }}>{props.textPrimary}</h1>
      <h2 className='apply-font content-text header-bg-text base-text text-secondary' style={{ color: props.secondaryColor, backgroundColor: props.secondaryBgColor }}>{props.textSecondary}</h2>
      <h2 className='apply-font content-text header-bg-text base-text text-tertiary' style={{ color: props.tertiaryColor, backgroundColor: props.tertiaryBgColor }}>{props.textTertiary}</h2>
      <h2 className='apply-font content-text base-text text-quarternary' style={{ color: props.primaryColor }}>{props.textQuaternary}</h2>
      <h2 className='apply-font content-text base-text text-quinary' style={{ color: props.primaryColor }}>{props.textQuinary}</h2>
      <h2 className='apply-font content-text base-text text-senary' style={{ fontSize: parseInt(props.textSenarySize), color: props.primaryColor }}>{props.textSenary}</h2>
      <p className='apply-font content-text base-text text-septenary' style={{ color: props.septenaryColor, whiteSpace: 'pre-line' }}>{props.textSeptenary}</p>
      <p className='apply-font content-text base-text text-octonary' style={{ color: props.octonaryColor, whiteSpace: 'pre-line' }}>{props.textOctonary}</p>
      {props.showLove && <p className='apply-font content-text content-copy'><img src={quidsterLogo} alt='Quidster logo' /></p>}
    </div>
  )
}

export default Content
