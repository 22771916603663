const strings = {
    textLabels: {
        "primary": "Primary",
        "secondary": "Secondary",
        "Tertiary": "Tertiary",
        "Quaternary": "Quaternary",
        "Quinary": "Quinary",
        "Senary": "Senary",
        "Septenary": "Septenary",
        "Octonary": "Octonary",
    },
    buttons: {
        "generate": "Skapa banner",
        "download": "Ladda ner poster",
        "reset": "Ta bort",
        "uploadBackgroundImage": "Bakgrundsbild",
        "removeBackgroundImage": 'Bakgrundsbild ✕',
        "uploadPrimaryMoveableImage": "Logo 1",
        "removePrimaryMoveableImage": 'Logo 1 ✕',
        "uploadSecondaryMoveableImage": "Logo 2",
        "removeSecondaryMoveableImage": 'Logo 2 ✕',
        "textColor": "Färg",
        "backgroundColor": "Bg färg",
        "instaPortrait": "Portait",
        "instaSquare": "Square",
        "instaStory": "Story",
        "copy": "Visa kärlek"
    },
    placeholder: {
        "matchday": "Titel",
        "hometeam": "ex hemmalag",
        "awayteam": "ex bortalag",
        "venue": "ex plats",
        "time": "ex tid",
        "Senary": "Extrafält",
        "Septenary": "Textfält",
        "Octonary": "Textfält",
    },
    size: {
        portrait: {
            height: '1350',
            width: '1080'
        },
        square: {
            height: '1080',
            width: '1080'
        },
        story: {
            height: '1920',
            width: '1080'
        }
    },
}

export default strings;