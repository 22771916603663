import * as React from 'react'
import { createRoot } from 'react-dom/client';
import domtoimage from 'dom-to-image-more'
import { useState, useRef, useEffect } from 'react';
import Content from './components/content'
import Form from './components/form'
import Result from './components/result'
import Footer from './components/footer'
import ImageList from './components/imageList';
import LeftSideBar from './components/leftSideBar';
import RightSideBar from './components/rightSideBar';
import './styles/styles.css'
import { createBlobFromBase64 } from './utilities/helper';

function App() {
  let contentContainerRef = useRef<HTMLElement | null>(null);
  let resultContainerRef = useRef<HTMLElement | null>(null);

  const [backgroundImage, setBackgroundImage] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [logo, setLogo] = useState('');
  const [moveableSecondaryImage, setMoveableSecondaryImage] = useState('');
  const [moveablePrimaryImage, setMoveablePrimaryImage] = useState('');
  const [textPrimary, setTextPrimary] = useState('');
  const [textPrimarySize, setTextPrimarySize] = useState('110');
  const [textSenarySize, setTextSenarySize] = useState('48');
  const [textSecondary, setTextSecondary] = useState('');
  const [textTertiary, setTextTertiary] = useState('');
  const [textQuaternary, setTextQuaternary] = useState('');
  const [textQuinary, setTextQuinary] = useState('');
  const [textSenary, setTextSenary] = useState('');
  const [textSeptenary, setTextSeptenary] = useState('');
  const [textOctonary, setTextOctonary] = useState('');
  const [activeFont, setActiveFont] = useState("Josefin Sans");
  const [isBannerGenerated, setIsBannerGenerated] = useState(false);
  const [instagramSize, setInstagramSize] = useState('');
  const [resultDimensions, setResultDimensions] = useState({ height: 0, width: 0 } as any);
  const [primaryColor, setPrimaryColor] = useState('#ffffff');
  const [secondaryColor, setSecondaryColor] = useState('#ffffff');
  const [tertiaryColor, setTertiaryColor] = useState('#ffffff');
  const [septenaryColor, setSeptenaryColor] = useState('#ffffff');
  const [octonaryColor, setOctonaryColor] = useState('#ffffff');
  const [secondaryBgColor, setSecondaryBgColor] = useState('transparent');
  const [tertiaryBgColor, setTertiaryBgColor] = useState('transparent');
  const [gradientTop, setGradientTop] = useState('rgba(0,0,0,0)');
  const [gradientBottom, setGradientBottom] = useState('rgba(0,0,0,0)');
  const [showLove, setShowLove] = useState(false);
  const [leftSidebarOpen, setLeftSideBarOpen] = useState(false);
  const [rightSidebarOpen, setRightSideBarOpen] = useState(false);
  const [logo64, setLogo64] = useState('');
  const [primaryBase64, setPrimaryBase64] = useState('');
  const [secondaryBase64, setSecondaryBase64] = useState('');

  useEffect(() => {
    const textPrimary = JSON.parse(localStorage.getItem('textPrimary')!);
    const textSecondary = JSON.parse(localStorage.getItem('textSecondary')!);
    const textTertiary = JSON.parse(localStorage.getItem('textTertiary')!);
    const textQuaternary = JSON.parse(localStorage.getItem('textQuaternary')!);
    const textQuinary = JSON.parse(localStorage.getItem('textQuinary')!);
    const textSenary = JSON.parse(localStorage.getItem('textSenary')!);
    const textSeptenary = JSON.parse(localStorage.getItem('textSeptenary')!);
    const textOctonary = JSON.parse(localStorage.getItem('textOctonary')!);
    const primaryColor = JSON.parse(localStorage.getItem('primaryColor')!);
    const secondaryColor = JSON.parse(localStorage.getItem('secondaryColor')!);
    const tertiaryColor = JSON.parse(localStorage.getItem('tertiaryColor')!);
    // const activeFont = JSON.parse(localStorage.getItem('activeFont'));
    const textPrimarySize = JSON.parse(localStorage.getItem('textPrimarySize')!);
    const textSenarySize = JSON.parse(localStorage.getItem('textSenarySize')!);
    const secondaryBgColor = JSON.parse(localStorage.getItem('secondaryBgColor')!);
    const tertiaryBgColor = JSON.parse(localStorage.getItem('tertiaryBgColor')!);
    const septenaryColor = JSON.parse(localStorage.getItem('septenaryColor')!);
    const octonaryColor = JSON.parse(localStorage.getItem('octonaryColor')!);
    const backgroundImage = JSON.parse(localStorage.getItem('backgroundImage')!);
    const backgroundColor = JSON.parse(localStorage.getItem('backgroundColor')!);
    const gradientTop = JSON.parse(localStorage.getItem('gradientTop')!);
    const gradientBottom = JSON.parse(localStorage.getItem('gradientBottom')!);
    const instagramSize = JSON.parse(localStorage.getItem('instagramSize')!);
    const showLove = JSON.parse(localStorage.getItem('showLove')!);
    const logo64 = JSON.parse(localStorage.getItem('logo64')!);
    const primaryBase64 = JSON.parse(localStorage.getItem('primaryBase64')!);
    const secondaryBase64 = JSON.parse(localStorage.getItem('secondaryBase64')!);

    if (textPrimary || textSecondary || textTertiary || textQuaternary || textQuinary || textSenary || textSeptenary || textOctonary || activeFont || primaryColor || secondaryColor || tertiaryColor || septenaryColor || octonaryColor || backgroundImage || gradientTop || gradientBottom || logo || moveableSecondaryImage || moveablePrimaryImage || backgroundColor || instagramSize || showLove || textPrimarySize || textSenarySize || secondaryBgColor || tertiaryBgColor || logo64 || primaryBase64 || secondaryBase64) {
      setTextPrimary(textPrimary);
      setTextSecondary(textSecondary);
      setTextTertiary(textTertiary);
      setTextQuaternary(textQuaternary);
      setTextQuinary(textQuinary);
      setTextSenary(textSenary);
      setTextSeptenary(textSeptenary);
      setTextOctonary(textOctonary);
      // setActiveFont(activeFont ? activeFont : "Josefin Sans");
      setPrimaryColor(primaryColor);
      setSecondaryColor(secondaryColor);
      setTertiaryColor(tertiaryColor);
      setSeptenaryColor(septenaryColor);
      setOctonaryColor(octonaryColor);
      setBackgroundImage(backgroundImage);
      setBackgroundColor(backgroundColor);
      setGradientTop(gradientTop);
      setGradientBottom(gradientBottom);
      setInstagramSize(instagramSize);
      setShowLove(showLove);
      setTextPrimarySize(textPrimarySize);
      setTextSenarySize(textSenarySize);
      setSecondaryBgColor(secondaryBgColor);
      setTertiaryBgColor(tertiaryBgColor);
      setLogo64(logo64);
      setPrimaryBase64(primaryBase64);
      setSecondaryBase64(secondaryBase64);
    }

    if (!backgroundImage) {
      document.getElementById('content')!.style.backgroundColor = `${backgroundColor}`;
    }
    if (!backgroundImage && !backgroundColor) {
      document.getElementById('content')!.style.backgroundColor = '#000435';
    }
  }, []);

  useEffect(() => {
    if (primaryBase64) {
      setMoveablePrimaryImage(primaryBase64)
    }
    if (secondaryBase64) {
      setMoveableSecondaryImage(secondaryBase64)
    }
    if (logo64) {
      setLogo(logo64)
    }

    localStorage.setItem("textPrimary", JSON.stringify(textPrimary));
    localStorage.setItem("textSecondary", JSON.stringify(textSecondary));
    localStorage.setItem("textTertiary", JSON.stringify(textTertiary));
    localStorage.setItem("textQuaternary", JSON.stringify(textQuaternary));
    localStorage.setItem("textQuinary", JSON.stringify(textQuinary));
    localStorage.setItem("textSenary", JSON.stringify(textSenary));
    localStorage.setItem("textSeptenary", JSON.stringify(textSeptenary));
    localStorage.setItem("textOctonary", JSON.stringify(textOctonary));
    // localStorage.setItem("activeFont", JSON.stringify(activeFont));
    localStorage.setItem("primaryColor", JSON.stringify(primaryColor));
    localStorage.setItem("secondaryColor", JSON.stringify(secondaryColor));
    localStorage.setItem("tertiaryColor", JSON.stringify(tertiaryColor));
    localStorage.setItem("septenaryColor", JSON.stringify(septenaryColor));
    localStorage.setItem("octonaryColor", JSON.stringify(octonaryColor));
    localStorage.setItem("backgroundImage", JSON.stringify(backgroundImage));
    localStorage.setItem("instagramSize", JSON.stringify(instagramSize));
    localStorage.setItem("showLove", JSON.stringify(showLove));
    localStorage.setItem("textPrimarySize", JSON.stringify(textPrimarySize));
    localStorage.setItem("textSenarySize", JSON.stringify(textSenarySize));
    localStorage.setItem("secondaryBgColor", JSON.stringify(secondaryBgColor));
    localStorage.setItem("tertiaryBgColor", JSON.stringify(tertiaryBgColor));
    localStorage.setItem("gradientTop", JSON.stringify(gradientTop));
    localStorage.setItem("gradientBottom", JSON.stringify(gradientBottom));
    localStorage.setItem("logo64", JSON.stringify(logo64));
    localStorage.setItem("primaryBase64", JSON.stringify(primaryBase64));
    localStorage.setItem("secondaryBase64", JSON.stringify(secondaryBase64));
  }, [textPrimary, textSecondary, textTertiary, textQuaternary, textQuinary, textSenary, textSeptenary, textOctonary, activeFont, primaryColor, secondaryColor, tertiaryColor, septenaryColor, octonaryColor, backgroundImage, gradientTop, gradientBottom, logo, moveableSecondaryImage, moveablePrimaryImage, instagramSize, showLove, textPrimarySize, textSenarySize, secondaryBgColor, tertiaryBgColor, logo64, primaryBase64, secondaryBase64]);

  function handleInputChange(event) {
    if (event.target.name === 'text-primary') {
      return setTextPrimary(event.target.value)
    }
    if (event.target.name === 'text-secondary') {
      return setTextSecondary(event.target.value)
    }
    if (event.target.name === 'text-tertiary') {
      return setTextTertiary(event.target.value)
    }
    if (event.target.name === 'text-quaternary') {
      return setTextQuaternary(event.target.value)
    }
    if (event.target.name === 'text-quinary') {
      return setTextQuinary(event.target.value)
    }
    if (event.target.name === 'text-senary') {
      return setTextSenary(event.target.value)
    }
    if (event.target.name === 'text-septenary') {
      return setTextSeptenary(event.target.value)
    }
    if (event.target.name === 'text-octonary') {
      return setTextOctonary(event.target.value)
    }
  }

  function handleImageLogoChange(event) {
    createBlobFromBase64(event.target.files[0], setLogo64);
    setLogo(window.URL.createObjectURL(event.target.files[0]))
  }

  function handleMoveablePrimaryImageChange(event) {
    createBlobFromBase64(event.target.files[0], setPrimaryBase64);
    setMoveablePrimaryImage(window.URL.createObjectURL(event.target.files[0]))
  }

  function handleMoveableSecondaryImageChange(event) {
    createBlobFromBase64(event.target.files[0], setSecondaryBase64)
    setMoveableSecondaryImage(window.URL.createObjectURL(event.target.files[0]))
  }

  function handleBannerGeneration() {
    // Remove any existing images
    if (resultContainerRef.current!.childNodes.length > 0) {
      resultContainerRef.current!.removeChild(resultContainerRef.current!.childNodes[0])
    }

    console.log(resultDimensions.height, resultDimensions.width)
    const scale = 1;
    domtoimage.toPng(contentContainerRef.current, {
      // height: resultDimensions.height * scale,
      // width: resultDimensions.width * scale,
      style: {
        transform: 'scale(' + scale + ')',
        transformOrigin: 'top left'
      }
    })
      .then((dataUrl) => {
        const img = new Image();
        img.src = dataUrl;
        resultContainerRef.current!.appendChild(img);

        const link = document.createElement('a');
        const breakRow = document.createElement('br');
        link.download = `poster_${textPrimary.replace(' ', '-')}.png`;
        link.href = dataUrl;
        const linkText = document.createTextNode('Ladda ner');
        link.appendChild(linkText);
        link.title = 'Download';
        document.getElementById('result')!.appendChild(breakRow);
        document.getElementById('result')!.appendChild(link);

        setIsBannerGenerated(true);
      }).catch(function (error) {
        console.error('ojdå, något gick fel!', error);
      });

    let result = document.getElementById("result");
    setTimeout(() => {
      result && result.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 1000);
  }

  function handleBannerReset() {
    // Remove existing child node inside result container (generated banner image)
    resultContainerRef.current!.removeChild(resultContainerRef.current!.childNodes[0])
    const div = document.getElementById('result');
    while(div.firstChild){
        div.removeChild(div.firstChild);
    }
    setIsBannerGenerated(false)
  }

  // Choose random images from images fetched from api.imgflip.com
  // function handleImageChange() {
  //   // Choose random image
  //   const image = images[Math.floor(Math.random() * images.length)]

  //   // Update activeImage state
  //   setActiveImage(image.url)
  // }

  return (
    <div className="App">
      <LeftSideBar
        leftSidebarOpen={leftSidebarOpen}
        setLeftSideBarOpen={setLeftSideBarOpen}
      />
      <RightSideBar
        rightSidebarOpen={rightSidebarOpen}
        setRightSideBarOpen={setRightSideBarOpen}
      />
      <Form
        textPrimary={textPrimary}
        textSecondary={textSecondary}
        textTertiary={textTertiary}
        textQuaternary={textQuaternary}
        textQuinary={textQuinary}
        textSenary={textSenary}
        textSeptenary={textSeptenary}
        textOctonary={textOctonary}
        logo={logo}
        logo64={logo64}
        moveableSecondaryImage={moveableSecondaryImage}
        activeFont={activeFont}
        moveablePrimaryImage={moveablePrimaryImage}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        tertiaryColor={tertiaryColor}
        septenaryColor={septenaryColor}
        octonaryColor={octonaryColor}
        tertiaryBgColor={tertiaryBgColor}
        secondaryBgColor={secondaryBgColor}
        textPrimarySize={textPrimarySize}
        textSenarySize={textSenarySize}
        setResultDimensions={setResultDimensions}
        setTextSenarySize={setTextSenarySize}
        setTextPrimarySize={setTextPrimarySize}
        showLove={showLove}
        primaryBase64={primaryBase64}
        secondaryBase64={secondaryBase64}
        setShowLove={setShowLove}
        setLogo={setLogo}
        setLogo64={setLogo64}
        instagramSize={instagramSize}
        setMoveableSecondaryImage={setMoveableSecondaryImage}
        setMoveablePrimaryImage={setMoveablePrimaryImage}
        setPrimaryColor={setPrimaryColor}
        setSecondaryColor={setSecondaryColor}
        setTertiaryColor={setTertiaryColor}
        setTertiaryBgColor={setTertiaryBgColor}
        setSecondaryBgColor={setSecondaryBgColor}
        setSeptenaryColor={setSeptenaryColor}
        setOctonaryColor={setOctonaryColor}
        setActiveFont={setActiveFont}
        setInstagramSize={setInstagramSize}
        handleImageLogoChange={handleImageLogoChange}
        handleMoveablePrimaryImageChange={handleMoveablePrimaryImageChange}
        handleMoveableSecondaryImageChange={handleMoveableSecondaryImageChange}
        handleInputChange={handleInputChange}
        handleBannerGeneration={handleBannerGeneration}
        handleBannerReset={handleBannerReset}
        isBannerGenerated={isBannerGenerated}
        setPrimaryBase64={setPrimaryBase64}
        setSecondaryBase64={setSecondaryBase64}
      />
      <ImageList
        setBackgroundImage={setBackgroundImage}
        backgroundColor={backgroundColor}
        setBackgroundColor={setBackgroundColor}
        gradientTop={gradientTop}
        gradientBottom={gradientBottom}
        setGradientTop={setGradientTop}
        setGradientBottom={setGradientBottom}
      />
      <Content
        backgroundImage={backgroundImage}
        activeFont={activeFont}
        logo={logo}
        moveableSecondaryImage={moveableSecondaryImage}
        showLove={showLove}
        moveablePrimaryImage={moveablePrimaryImage}
        contentContainerRef={contentContainerRef}
        textPrimarySize={textPrimarySize}
        textSenarySize={textSenarySize}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        tertiaryColor={tertiaryColor}
        septenaryColor={septenaryColor}
        octonaryColor={octonaryColor}
        secondaryBgColor={secondaryBgColor}
        tertiaryBgColor={tertiaryBgColor}
        textPrimary={textPrimary}
        textSecondary={textSecondary}
        textTertiary={textTertiary}
        textQuaternary={textQuaternary}
        textQuinary={textQuinary}
        textSenary={textSenary}
        textSeptenary={textSeptenary}
        gradientTop={gradientTop}
        gradientBottom={gradientBottom}
        textOctonary={textOctonary}
        instagramSize={instagramSize}
      />
      <Result resultContainerRef={resultContainerRef} />
      <Footer />
    </div>
  )
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
